.grey-border {
  border: 2px solid #cccccc;
  border-radius: 8px;
  margin: 5px;
  padding: 10px 0px;
}

.grey-background {
  border-radius: 12px;
  padding: 16px;
  margin: 0px 8px;
  min-height: 115px;
}

.elevated {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.countdown {
  border-radius: 12px;
  color: white;
  padding: 4px 0px;
  width: 42px;
  margin: auto;
  margin-bottom: 8px;
}

@media (max-width: 600px) {
  .grey-background {
    min-height: 0px;
    margin: 8px 0px;
  }
}
