.blue-text {
  color: #0f6eff;
}

.purple-text {
  color: #3300cc;
}

.grey-text {
  color: #9b9999;
}

.normal {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 800 !important;
}

.dash-card {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background: #0f6eff;
}

.hide-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0f6eff;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 600px) {
  .header-logo {
    height: 16px !important;
  }
}

.clickable {
  text-decoration: none;
}

.clickable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.header-logo {
  height: 24px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

table {
  border-collapse: collapse;
}
